import { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Constants from '../../utils/Constants';
import useAuthContext from '../../apps/common/modules/auth/hooks/useAuthContext';
import { useAppDispatch, useAppSelector } from '../../Redux/Store';
import { commonActions, toastMessage } from '../../Redux/Reducer/common';
import { sideNavTabs } from '../../utils/Interface/CommonInterface';
import { CommonConstants } from '../../utils/constants/CommonConstants';
import { zendeskAPI } from '../../helpers/CommonFunc';
import { getToastContent } from '../../utils/ToastContent';
import DeleteImgIcon from '../../Assets/DeleteImgIcon';

const SupportModal = () => {
    const dispatch = useAppDispatch();
    const { showSupportModal } = useAppSelector(state => state.common);
    const { currentSelectedTab } = useAppSelector(state => state.common);

    const handleClose = () =>
        dispatch(commonActions.setShowSupportModal(false));

    const { submitTicket } = zendeskAPI(currentSelectedTab);
    const { getUserEmail } = useAuthContext();

    const [userName, setUserName] = useState<string>('');

    useEffect(() => {
        setUserName(getUserEmail());
    }, [getUserEmail]);

    const [formData, setFormData] = useState({
        subject: '',
        description: '',
        attachment: [] as File[],
    });

    const [formError, setFormError] = useState({
        subject: false,
        description: false,
    });

    const handleChange = (name: string, value: string) => {
        setFormData(prev => ({
            ...prev,
            [name]: value,
        }));
        if (value && formError[name as keyof typeof formError]) {
            setFormError(prev => ({ ...prev, [name]: false }));
        }
    };

    const handleFileChange = (file: File) => {
        const fileExtension = file.name.split('.').pop()?.toLowerCase();

        if (
            !fileExtension ||
            !Constants.createTicketFileTypes.includes(fileExtension)
        ) {
            toast.error(Constants.supportModal.fileUploadFailure);
            return;
        }

        if (file.size > Constants.validFileSizeInSupportTicket) {
            toast.error(Constants.supportModal.fileSizeExceeded);
            return;
        }
        setFormData(prev => ({
            ...prev,
            attachment: [...prev.attachment, file],
        }));
    };

    const validateForm = () => {
        const errors = {
            subject: formData.subject.trim() === '',
            description: formData.description.trim() === '',
        };
        setFormError(errors);
        return !Object.values(errors).some(error => error);
    };

    const handleSubmit = async () => {
        if (!validateForm()) return;
        const response = await submitTicket(formData, userName);
        if (
            response.status === Constants.responseCode.NO_CONTENT ||
            response.status === Constants.responseCode.SUCCESS
        ) {
            dispatch(toastMessage(getToastContent(response.status)));
        } else {
            dispatch(toastMessage(getToastContent(response.status)));
        }
        handleReset();
    };

    const handleReset = () => {
        setFormData({ subject: '', description: '', attachment: [] });
        setFormError({ subject: false, description: false });
        handleClose();
    };

    const handleRemoveAttachment = index => {
        const updatedAttachments = formData.attachment.filter(
            (_, i) => i !== index,
        );
        setFormData(prev => ({ ...prev, attachment: updatedAttachments }));
    };

    const renderSupportModalContent = () => {
        return (
            <Modal
                show={showSupportModal}
                className="pt-5"
                onHide={handleClose}
                centered
            >
                <Modal.Header closeButton className="border-0">
                    <Modal.Title className="text-dark bold">
                        <b>{Constants.supportModal.title}</b>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <p>
                            <b>{Constants.supportModal.phoneNumberLabel}</b>{' '}
                            {Constants.supportModal.phoneNumber}
                        </p>
                        <p>
                            <b>{Constants.supportModal.emailLabel}</b>{' '}
                            {Constants.supportModal.email}
                        </p>
                    </div>
                </Modal.Body>
            </Modal>
        );
    };

    const renderTicketForm = () => (
        <Modal
            show={showSupportModal}
            className="pt-5"
            onHide={handleClose}
            centered
        >
            <Modal.Header>
                <Modal.Title className="text-dark bold">
                    <b>
                        {currentSelectedTab === sideNavTabs.BuyPo
                            ? Constants.supportModal.cattlePOTitle
                            : Constants.supportModal.financeTitle}
                    </b>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="d-flex justify-content-between align-items-center">
                    <p>
                        <b>{Constants.supportModal.phoneNumberLabel}</b>{' '}
                        {Constants.supportModal.phoneNumber}
                    </p>
                    <a
                        href={
                            currentSelectedTab === sideNavTabs.BuyPo
                                ? Constants.Routes.CATTLE_PO_USER_MANUAL
                                : Constants.Routes.FINANCE_USER_MANUAL
                        }
                        className="user-manual-link cursor-pointer"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {Constants.supportModal.userManual}
                    </a>
                </div>
                <p>
                    <b>{Constants.supportModal.emailLabel}</b>{' '}
                    {Constants.supportModal.email}
                </p>
                <form className="support-modal-form">
                    <div className="col-12 col-md-6">
                        <label htmlFor="subject" className="form-label">
                            {Constants.supportModal.subject}
                            <span className="text-danger">*</span>
                        </label>
                        <input
                            type="text"
                            id="subject"
                            className={`form-control ${formError.subject ? 'is-invalid' : ''}`}
                            placeholder="Enter Subject"
                            value={formData.subject}
                            onChange={e =>
                                handleChange('subject', e.target.value)
                            }
                            required
                        />
                    </div>
                    <div className="form-group mb-3 mt-3">
                        <label htmlFor="description" className="form-label">
                            {Constants.supportModal.Description}
                            <span className="text-danger">*</span>
                        </label>
                        <textarea
                            id="description"
                            className={`form-control ${formError.description ? 'is-invalid' : ''}`}
                            rows={4}
                            placeholder="Enter description of issue"
                            value={formData.description}
                            onChange={e =>
                                handleChange('description', e.target.value)
                            }
                            required
                        />
                    </div>
                    <label htmlFor="file-upload" className="form-label mx-2">
                        {Constants.supportModal.attachment}
                    </label>
                    <div className="d-flex align-items-center">
                        <input
                            type="file"
                            id="file-upload"
                            className="d-none"
                            accept={Constants.fileAcceptType}
                            onChange={e =>
                                e.target.files &&
                                handleFileChange(e.target.files[0])
                            }
                        />
                        <button
                            className="upload-button"
                            type="button"
                            onClick={e => {
                                e.preventDefault();
                                document.getElementById('file-upload')?.click();
                            }}
                        >
                            {Constants.supportModal.uploadFile}
                        </button>
                        <span className="mx-4 text-muted">or</span>
                        <div
                            className="drag-drop-box file-drop mx-1"
                            onDragOver={e => e.preventDefault()}
                            onDrop={e => {
                                e.preventDefault();
                                e.stopPropagation();
                                if (
                                    e.dataTransfer.files &&
                                    e.dataTransfer.files.length > 0
                                ) {
                                    handleFileChange(e.dataTransfer.files[0]);
                                }
                            }}
                        >
                            {Constants.supportModal.dragDrop}
                        </div>
                    </div>
                    <div className="uploaded-files mt-4">
                        {formData.attachment.length > 0 && (
                            <ul className="list-group">
                                {formData.attachment.map((file, index) => (
                                    <li
                                        key={index}
                                        className="d-flex justify-content-between align-items-center"
                                    >
                                        <span>{file.name}</span>
                                        <DeleteImgIcon
                                            onClick={() =>
                                                handleRemoveAttachment(index)
                                            }
                                            className="cursor-pointer support-modal-delete-icon"
                                        />
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>
                    <div className="d-flex justify-content-end gap-2 mt-4">
                        <Button
                            onClick={handleReset}
                            variant="secondary"
                            className="warning secondary"
                        >
                            {CommonConstants.en.cancel}
                        </Button>
                        <Button
                            type="button"
                            variant="primary"
                            className="btn btn-warning"
                            onClick={handleSubmit}
                        >
                            {Constants.supportModal.submitTicket}
                        </Button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );

    return currentSelectedTab === sideNavTabs.Fm
        ? renderSupportModalContent()
        : renderTicketForm();
};

export default SupportModal;
