import { FC, lazy } from 'react';

import Constants from '../Constants';
import FinancialRoutes from './FinancialApp/FinancialRoutes';

import {
    BuyPoIcon,
    CoreCattleManagementIcon,
    FeedBillsIcon,
    FeedIcon,
    FeedLotsIcon,
    HealthIcon,
    HedgesIcon,
    HomeIcon,
    InterestRateIcon,
    InventoryIcon,
    LotIcon,
    SettingsIcon,
    ShipPoIcon,
    StatementsIcon,
    TruckingCompanyIcon,
    CalendarIcon,
} from '../../Assets';
import { IAppRoute, IRoute } from '../routes/Interface';
import { AppRoles } from '../../apps/common/modules/auth/constants/AuthConstants';
import Hospital from '../../components/pages/Hospital';
import ProjectBreakevenReport from '../../components/pages/ProjectBreakevenReport';
import ShawnaSpreadSheet from '../../components/pages/ShawnaSpreadSheet';
import CreatePen from '../../components/pages/CreatePen';
import { feedlotManagerFeatures } from '../../apps/feedlotManager/featureFlags/feedlotManagerFeatures';
import TruckDetails from '../../components/pages/TruckDetails';
import LoaderDetails from '../../components/pages/LoaderDetails';
import QuickBookIntegration from '../../components/pages/QuickBookIntegration';
import MixingBinDetails from '../../components/pages/MixingBinDetails';
import LoginPage from '../../apps/common/modules/auth/pages/LoginPage';
import YardageDetails from '../../components/pages/YardageDetails';
import LocationDetails from '../../components/pages/LocationDetails';
import DestinationDetails from '../../components/pages/DestinationDetails';
import TruckingCompanyDetails from '../../components/pages/TruckingCompanyDetails';
import UserManagement from '../../apps/feedlotManager/modules/settings/userManagement/components/UserManagement';
import BuyerDetails from '../../components/pages/BuyerDetails';
import OwnerDetails from '../../components/pages/OwnerDetails';
import TechDetails from '../../components/pages/TechDetails';
import RiderDetails from '../../components/pages/RiderDetails';
import TankDetails from '../../components/pages/TankDetails';
import { ForgotPasswordPage } from '../../apps/common/modules/auth/pages/ForgotPasswordPage';
import { TransientResetPasswordPage } from '../../apps/common/modules/auth/pages/TransientPasswordResetPage';
import VendorDetails from '../../components/pages/VendorDetails';
import CreateScaleTicket from '../../modules/commodities/scaleTickets/components/CreateScaleTicket';
import { commonFeatures } from '../../apps/common/featureFlags/commonFeatures';
import { cattlePO } from '../../apps/shippingTracker/featureFlags/cattlePOFeatures';

const { isBP1085_buyerSetting, isBP1084_payeeSetting, isBP1086_ownerSettings } =
    cattlePO;
const {
    isCA9685_localAuthLoginScreen,
    isCA13354On_RestrictLegacyUsersOnUserManagement,
} = commonFeatures;

const Dashboard = lazy<FC>(() => import('../../components/pages/Dashboard'));
const SignUp = lazy<FC>(
    () => import('../../apps/common/modules/auth/pages/SignUpPage'),
);
const Callback = lazy<FC>(() => import('../../components/pages/Callback'));
const PageNotFound = lazy<FC>(
    () => import('../../components/pages/PageNotFound'),
);

const ManageCommodities = lazy<FC>(
    () => import('../../modules/commodities/manageCommodities/Index'),
);
const AddCommoditydjustment = lazy<FC>(
    () =>
        import(
            '../../modules/commodities/manageCommodities/components/CommodityAdjustment'
        ),
);
const UpdateContract = lazy<FC>(
    () =>
        import(
            '../../modules/commodities/manageCommodities/components/UpdateContract'
        ),
);
const Billing = lazy<FC>(() => import('../../components/pages/Billing'));
const BillingFinalInvoicePreview = lazy<FC>(
    () => import('../../components/pages/BillingFinalInvoicePreview'),
);
const Treatments = lazy<FC>(() => import('../../components/pages/Treatments'));
const EditTreatment = lazy<FC>(
    () => import('../../components/pages/EditTreatment'),
);
const UpdateMedicalItemPage = lazy<FC>(
    () => import('../../components/pages/UpdateMedicalItemPage'),
);
const AddProcessingChargeoutPage = lazy<FC>(
    () => import('../../components/pages/AddProcessingChargeoutPage'),
);

const AddProcessingWorksheetPage = lazy<FC>(
    () => import('../../components/pages/AddProcessingWorksheetPage'),
);
const ProcessingLotPage = lazy<FC>(
    () => import('../../components/pages/ProcessingLotPage'),
);
const LotSummaryReport = lazy<FC>(
    () => import('../../components/pages/LotSummaryReport'),
);
const PenDetails = lazy<FC>(() => import('../../components/pages/PenDetails'));
const EditScaleTicket = lazy<FC>(
    () =>
        import(
            '../../modules/commodities/scaleTickets/components/EditScaleTicket'
        ),
);
const BuyPoList = lazy<FC>(
    () => import('../../apps/shippingTracker/modules/BuyPo/pages/BuyPoList'),
);
const BuyPoForm = lazy<FC>(
    () => import('../../apps/shippingTracker/modules/BuyPo/pages/BuyPoForm'),
);
const CattlePoCalendar = lazy<FC>(
    () => import('../../apps/shippingTracker/modules/calendar/Calendar'),
);
const TruckingCompanyView = lazy<FC>(
    () =>
        import(
            '../../apps/shippingTracker/modules/truckingCompany/TruckingCompanyView'
        ),
);
const BuyLocationSettings = lazy<FC>(
    () =>
        import(
            '../../apps/shippingTracker/modules/tenantSettings/components/BuyLocationSettings'
        ),
);
const TruckingCompanySettings = lazy<FC>(
    () =>
        import(
            '../../apps/shippingTracker/modules/tenantSettings/components/TruckingCompanySettings'
        ),
);
const DestinationSettings = lazy<FC>(
    () =>
        import(
            '../../apps/shippingTracker/modules/tenantSettings/components/DestinationSettings'
        ),
);
const OwnerSettings = lazy<FC>(
    () =>
        import(
            '../../apps/shippingTracker/modules/tenantSettings/components/OwnerSettings'
        ),
);
const BuyerSettings = lazy<FC>(
    () =>
        import(
            '../../apps/shippingTracker/modules/tenantSettings/components/BuyerSettings'
        ),
);
const VariableSettings = lazy<FC>(
    () =>
        import(
            '../../apps/shippingTracker/modules/tenantSettings/components/VariableSettings'
        ),
);
const TermSettings = lazy<FC>(
    () =>
        import(
            '../../apps/shippingTracker/modules/tenantSettings/components/TermSettings'
        ),
);
const PayeeSettings = lazy<FC>(
    () =>
        import(
            '../../apps/shippingTracker/modules/tenantSettings/components/PayeeSettings'
        ),
);
const ReceiveCattleList = lazy<FC>(
    () => import('../../components/pages/ReceiveCattleList'),
);
const ReceiveCattleForm = lazy<FC>(
    () => import('../../components/pages/ReceiveCattleForm'),
);
const ShipPoList = lazy<FC>(
    () =>
        import(
            '../../apps/shippingTracker/modules/shippo/components/ShipPoList'
        ),
);
const ShipCattle = lazy<FC>(() => import('../../components/pages/ShipCattle'));
const UpdateShipCattle = lazy<FC>(
    () => import('../../components/pages/UpdateShipCattle'),
);
const MoveCattle = lazy<FC>(() => import('../../components/pages/MoveCattle'));
const EditPenRationTemplates = lazy<FC>(
    () => import('../../components/pages/EditPenRationTemplates'),
);
const FeedRouteCreation = lazy<FC>(
    () => import('../../components/pages/FeedRouteCreation'),
);
const Ration = lazy<FC>(() => import('../../components/pages/Ration'));
const RationFormPage = lazy<FC>(
    () => import('../../components/pages/RationFormPage'),
);
const LotSummary = lazy<FC>(() => import('../../components/pages/LotSummary'));
const CreateCustomer = lazy<FC>(
    () => import('../../components/pages/CreateCustomer'),
);
const HedgePurchaseForm = lazy<FC>(
    () =>
        import(
            '../../apps/financeManager/modules/hedges/components/HedgePurchaseForm'
        ),
);
const HedgeSaleForm = lazy<FC>(
    () =>
        import(
            '../../apps/financeManager/modules/hedges/components/HedgeSaleForm'
        ),
);
const CattlePurchasePage = lazy<FC>(
    () => import('../../components/pages/CattlePurchasePage'),
);
const CattleSalePage = lazy<FC>(
    () => import('../../components/pages/CattleSalePage'),
);
const CattleWeightPage = lazy<FC>(
    () => import('../../components/pages/CattleWeightPage'),
);
const CattleDeadPage = lazy<FC>(
    () => import('../../components/pages/CattleDeadPage'),
);
const CreateFeedBillPage = lazy<FC>(
    () =>
        import(
            '../../apps/financeManager/modules/feedbill/components/CreateFeedBillPage'
        ),
);
const CreateFeedLot = lazy<FC>(
    () => import('../../components/pages/CreateFeedLot'),
);
const CreateLot = lazy<FC>(() => import('../../components/pages/CreateLot'));
const Settings = lazy<FC>(
    () => import('../../apps/financeManager/modules/settings/pages/Settings'),
);
const FeedLots = lazy<FC>(
    () => import('../../apps/financeManager/modules/Feedlots/FeedLots'),
);
const FeedBills = lazy<FC>(
    () =>
        import(
            '../../apps/financeManager/modules/feedbill/components/FeedBills'
        ),
);
const CustomerMonthlyReport = lazy<FC>(
    () => import('../../components/pages/CustomerMonthlyReport'),
);
const Hedges = lazy<FC>(
    () => import('../../apps/financeManager/modules/hedges/components/Hedges'),
);
const EditFeedBillPage = lazy<FC>(
    () =>
        import(
            '../../apps/financeManager/modules/feedbill/components/EditFeedBillPage'
        ),
);
const CustomerCloseout = lazy<FC>(
    () => import('../../components/pages/CustomerCloseout'),
);
const AddProcessingProtocolPage = lazy<FC>(
    () => import('../../components/pages/AddProcessingProtocol'),
);
const PenRationScheduleTemplates = lazy<FC>(
    () => import('../../components/pages/PenRationScheduleTemplates'),
);
const Products = lazy<FC>(
    () => import('../../apps/common/modules/products/pages/Products'),
);
const UpdatePenRationScheduleTemplates = lazy<FC>(
    () => import('../../components/pages/UpdatePenRationScheduleTemplates'),
);
const RationFeedOrder = lazy<FC>(
    () => import('../../components/pages/RationFeedOrder'),
);
const FMDashboard = lazy<FC>(
    () => import('../../components/pages/FMDashboard'),
);
const AddMedicalInventory = lazy<FC>(
    () => import('../../components/pages/AddMedicalInventory'),
);
const MedicalInventoryDetails = lazy<FC>(
    () => import('../../components/pages/MedicalInventoryDetails'),
);
const MedicalItemList = lazy<FC>(
    () => import('../../components/pages/MedicalItemList'),
);
const AddInventoryAdjustment = lazy<FC>(
    () => import('../../components/pages/AddInventoryAdjustment'),
);
const AddTreatmentProtocolPage = lazy<FC>(
    () => import('../../components/pages/AddTreatmentProtocolPage'),
);
const HealthTreatmentProtocols = lazy<FC>(
    () => import('../../components/pages/HealthTreatmentProtocols'),
);
const ScaleTicketLoad = lazy<FC>(
    () => import('../../modules/commodities/scaleTickets/Index'),
);
const PenRationSchedule = lazy<FC>(
    () => import('../../components/pages/PenRationSchedule'),
);
const FeedCallRoute = lazy<FC>(
    () => import('../../modules/feed/feedCallRoute'),
);

const FeedReports = lazy<FC>(() => import('../../modules/feed/feedReports'));

const NewFeedCall = lazy<FC>(
    () => import('../../components/pages/NewFeedCall'),
);

const NewFeedCallV2 = lazy<FC>(
    () =>
        import(
            '../../apps/feedlotManager/modules/feed/feedCall/pages/NewFeedCallV2'
        ),
);

const LotSummaryDetails = lazy<FC>(
    () => import('../../components/pages/LotSummaryDetails'),
);

const ShipCattleForm = lazy<FC>(
    () => import('../../components/pages/ShipCattleForm'),
);
const ShipPOForm = lazy<FC>(
    () =>
        import(
            '../../apps/shippingTracker/modules/shippo/components/ShipPOForm'
        ),
);

const FeedLotDetails = lazy<FC>(
    () => import('../../apps/financeManager/modules/Feedlots/FeedLotDetails'),
);
const LotDetails = lazy<FC>(() => import('../../components/pages/LotDetails'));
const LotsPerOwnerPage = lazy<FC>(
    () => import('../../components/pages/LotsPerOwnerPage'),
);
const InterestRateChanges = lazy<FC>(
    () =>
        import(
            '../../apps/financeManager/modules/interestRates/pages/InterestRateChanges'
        ),
);
const Statements = lazy<FC>(() => import('../../components/pages/Statements'));
const CattleFaxes = lazy<FC>(
    () =>
        import(
            '../../apps/financeManager/modules/cattleFax/components/CattleFaxes'
        ),
);
const MonthlyFarmCreditReport = lazy<FC>(
    () => import('../../components/pages/MonthlyFarmCreditReport'),
);
const LotBalancesReport = lazy<FC>(
    () => import('../../components/pages/LotBalancesReport'),
);
const QuarterlyReport = lazy<FC>(
    () =>
        import(
            '../../apps/financeManager/modules/reports/quarterlyReport/pages/QuarterlyReportPage'
        ),
);
const EditHedge = lazy<FC>(
    () =>
        import('../../apps/financeManager/modules/hedges/components/EditHedge'),
);
const FeedStatus = lazy<FC>(() => import('../../components/pages/FeedStatus'));

const HotList = lazy<FC>(() => import('../../components/pages/HotList'));
const MortalityReport = lazy<FC>(
    () => import('../../components/pages/MortalityReport'),
);
const EditMortalityReport = lazy<FC>(
    () => import('../../components/pages/EditMortalityReport'),
);
const YardLayout = lazy<FC>(() => import('../../components/pages/YardLayout'));
const CommodityInventoryReport = lazy<FC>(
    () => import('../../modules/commodities/reports/index'),
);
const ManualFeedEntry = lazy<FC>(
    () => import('../../components/pages/ManualFeedEntry'),
);

const ShipCattleRealizerForm = lazy<FC>(
    () => import('../../components/pages/ShipCattleRealizerForm'),
);
const ReLotFormPage = lazy<FC>(
    () => import('../../components/pages/ReLotFormPage'),
);

const {
    isCA4898On_LoaderList,
    isCA9919On_ManageUsersPage,
    isCA10570On_VendorList,
    isCA10586On_refactorFeedCall,
    isCA12694On_SettingsForAdminAndFeedlotManagerFix,
    isCA12073On_QuickBooksIntegration,
} = feedlotManagerFeatures;

export const Roles = {
    ADMIN: 'Admin',
    ACCOUNT_ADMIN: 'AccountAdmin',
    MANAGER: 'Manager',
    BUYER: 'Buyer',
    COWBOY: 'Cowboy',
    FEEDLOT_MANAGER: 'FeedlotManager',
    FEED_TRUCK_DRIVER: 'FeedTruckDriver',
    FINANCE: 'Finance',
    HEALTH_MANAGER: 'HealthManager',
    INVOICE_MANAGER: 'InvoiceManager',
    OFFICE_MANAGER: 'OfficeManager',
    OPERATOR: 'Operator',
    TENANT_ADMIN: 'Super Admin',
    TRUCKING_COMPANY: 'TruckingCompany',
    WAREHOUSING_APP: 'WarehousingApp',
    USER: 'User',
};

const buyPoSideNavItem = {
    label: 'CATTLE PO',
    appRole: AppRoles.ShippingTracker,
    isAppEnabled: process.env.REACT_APP_SHIPPING_TRACKER_APP === 'on',
    roles: [
        Roles.ADMIN,
        Roles.TENANT_ADMIN,
        Roles.FEEDLOT_MANAGER,
        Roles.OFFICE_MANAGER,
        Roles.BUYER,
        Roles.TRUCKING_COMPANY,
    ],
    state: 'buyPo',
    key: 0,
    subBuyPoItemsPrimary: [
        {
            roles: [
                Roles.ADMIN,
                Roles.TENANT_ADMIN,
                Roles.FEEDLOT_MANAGER,
                Roles.OFFICE_MANAGER,
                Roles.BUYER,
            ],
            redirectUrl: '/calendar',
            icon: <CalendarIcon />,
            label: 'Calendar',
        },
        {
            roles: [
                Roles.ADMIN,
                Roles.TENANT_ADMIN,
                Roles.FEEDLOT_MANAGER,
                Roles.OFFICE_MANAGER,
                Roles.BUYER,
            ],
            redirectUrl: '/buypos',
            icon: <BuyPoIcon />,
            label: 'Buy POs',
        },
        {
            roles: [
                Roles.ADMIN,
                Roles.FEEDLOT_MANAGER,
                Roles.TENANT_ADMIN,
                Roles.OFFICE_MANAGER,
                Roles.BUYER,
            ],
            redirectUrl: '/shippos',
            icon: <ShipPoIcon />,
            label: 'Ship POs',
        },
        {
            roles: [
                Roles.ADMIN,
                Roles.TENANT_ADMIN,
                Roles.TRUCKING_COMPANY,
                Roles.OFFICE_MANAGER,
            ],
            redirectUrl: '/truckingcompany',
            icon: <TruckingCompanyIcon />,
            label: 'Trucking Company',
        },
    ],
    subBuyPoItemsSecondary: [
        {
            roles: isCA13354On_RestrictLegacyUsersOnUserManagement
                ? [Roles.TENANT_ADMIN, Roles.ADMIN, Roles.FEEDLOT_MANAGER]
                : [Roles.TENANT_ADMIN],
            icon: <SettingsIcon />,
            label: 'Settings',
            authType: Constants.AuthType.LOCAL,
            subSideNavItems: [
                {
                    roles: [Roles.TENANT_ADMIN],
                    redirectUrl: '/buylocationsettings',
                    label: 'Buy Location',
                },
                {
                    roles: [Roles.TENANT_ADMIN],
                    redirectUrl: '/destinationsettings',
                    label: 'Destination',
                },
                {
                    roles: [Roles.TENANT_ADMIN],
                    redirectUrl: '/truckingcompanysettings',
                    label: 'Trucking Company',
                },
                ...(isBP1085_buyerSetting
                    ? [
                          {
                              roles: [Roles.TENANT_ADMIN],
                              redirectUrl: '/buyersettings',
                              label: 'Buyer',
                          },
                      ]
                    : []),
                ...(isBP1086_ownerSettings
                    ? [
                          {
                              roles: [Roles.TENANT_ADMIN],
                              redirectUrl: '/ownersettings',
                              label: 'Owner',
                          },
                      ]
                    : []),
                ...(isBP1084_payeeSetting
                    ? [
                          {
                              roles: [Roles.TENANT_ADMIN],
                              redirectUrl: '/payeesettings',
                              label: 'Payee',
                          },
                      ]
                    : []),
                {
                    roles: [Roles.TENANT_ADMIN],
                    redirectUrl: '/variablesettings',
                    label: 'Variable Settings',
                },
                {
                    roles: [Roles.TENANT_ADMIN],
                    redirectUrl: '/termsettings',
                    label: 'Terms Settings',
                },
            ],
        },
    ],
    subBuyPoItemsTertiary: [
        {
            roles: [Roles.ADMIN, Roles.FEEDLOT_MANAGER, Roles.TENANT_ADMIN],
            redirectUrl: Constants.Routes.USER_MANAGEMENT,
            icon: <SettingsIcon />,
            label: 'User Management',
        },
    ],
};

const financialSideNavItem = {
    label: 'CATTLE FINANCE',
    appRole: AppRoles.CattleFinanceManager,
    isAppEnabled: process.env.REACT_APP_FINANCE_MANAGER_APP === 'on',
    roles: [Roles.ADMIN, Roles.FINANCE, Roles.TENANT_ADMIN],
    state: 'financial',
    key: 1,
    createButtonLabel: 'Create New',
    createButtonIcon: <i className="fal fa-plus"></i>,
    createButtonObj: [
        {
            redirectUrl: FinancialRoutes.App.CREATE_OWNER,
            label: 'New Owner',
        },
        {
            redirectUrl: FinancialRoutes.App.CREATE_FEEDLOT,
            label: 'New Feedlot',
        },
        {
            redirectUrl: FinancialRoutes.App.CREATE_LOT,
            label: 'New Lot',
        },
        {
            redirectUrl: FinancialRoutes.App.CREATE_FEEDBILL,
            label: 'New Feed Bill',
        },
        {
            redirectUrl: FinancialRoutes.App.CREATE_CATTLE_PURCHASE,
            label: 'New Cattle Purchase',
        },
        {
            redirectUrl: FinancialRoutes.App.CREATE_CATTLE_SALE,
            label: 'New Cattle Sale',
        },
        {
            redirectUrl: FinancialRoutes.App.CREATE_RE_LOT,
            label: 'New Re-Lot',
        },
        {
            redirectUrl: FinancialRoutes.App.CREATE_OPEN_HEDGE,
            label: 'Open Hedge',
        },
        {
            redirectUrl: FinancialRoutes.App.CREATE_CLOSE_HEDGE,
            label: 'Close Hedge',
        },
    ],
    subFinanceItems: [
        {
            roles: [Roles.ADMIN, Roles.FINANCE, Roles.TENANT_ADMIN],
            redirectUrl: FinancialRoutes.App.LOTS,
            icon: <LotIcon />,
            label: 'Lots',
        },
        {
            roles: [Roles.ADMIN, Roles.FINANCE, Roles.TENANT_ADMIN],
            redirectUrl: FinancialRoutes.App.FEEDLOTS,
            icon: <FeedLotsIcon />,
            label: 'Feedlots',
        },
        {
            roles: [Roles.ADMIN, Roles.FINANCE, Roles.TENANT_ADMIN],
            redirectUrl: FinancialRoutes.App.FEEDBILLS,
            icon: <FeedBillsIcon />,
            label: 'Feed Bills',
        },
        {
            roles: [Roles.ADMIN, Roles.FINANCE, Roles.TENANT_ADMIN],
            redirectUrl: FinancialRoutes.App.HEDGES,
            icon: <HedgesIcon />,
            label: 'Hedges',
        },
        {
            roles: [Roles.ADMIN, Roles.FINANCE, Roles.TENANT_ADMIN],
            redirectUrl: FinancialRoutes.App.STATEMENTS,
            icon: <StatementsIcon />,
            label: 'Statements',
        },
        {
            roles: [Roles.ADMIN, Roles.FINANCE, Roles.TENANT_ADMIN],
            redirectUrl: FinancialRoutes.App.CATTLE_FAXES,
            //!!!need icon for cattle faxes
            icon: <StatementsIcon />,
            label: 'Cattle Faxes',
        },
        {
            roles: [Roles.ADMIN, Roles.FINANCE, Roles.TENANT_ADMIN],
            redirectUrl: FinancialRoutes.App.INTEREST_RATE_CHANGES,
            icon: <InterestRateIcon />,
            label: 'Interest Rates',
        },
        {
            roles: [Roles.ADMIN, Roles.FINANCE, Roles.TENANT_ADMIN],
            redirectUrl: FinancialRoutes.App.SETTINGS,
            icon: <SettingsIcon />,
            label: 'Settings',
        },
        ...(isCA9919On_ManageUsersPage
            ? [
                  {
                      roles: [
                          Roles.ADMIN,
                          Roles.FEEDLOT_MANAGER,
                          Roles.TENANT_ADMIN,
                      ],
                      redirectUrl: Constants.Routes.USER_MANAGEMENT,
                      icon: <SettingsIcon />,
                      label: 'User Management',
                      ...(isCA13354On_RestrictLegacyUsersOnUserManagement
                          ? { authType: Constants.AuthType.LOCAL }
                          : {}),
                  },
              ]
            : []),
    ],
};

const feedlotManagerSideNavItem = {
    label: 'FEEDLOT MANAGER',
    appRole: AppRoles.FeedlotManager,
    isAppEnabled: process.env.REACT_APP_FEEDLOT_MANAGER_APP === 'on',
    roles: [
        Roles.ADMIN,
        Roles.FEEDLOT_MANAGER,
        Roles.OFFICE_MANAGER,
        Roles.FINANCE,
        Roles.HEALTH_MANAGER,
        Roles.COWBOY,
        Roles.FEED_TRUCK_DRIVER,
        Roles.OPERATOR,
        ...(isCA9685_localAuthLoginScreen ? [Roles.USER] : []),
    ],
    state: 'fm',
    key: 2,
    subFMItemsPrimary: [
        {
            accordionName: 'Dashboard',
            parentRoles: [
                Roles.ADMIN,
                Roles.FEEDLOT_MANAGER,
                Roles.OFFICE_MANAGER,
                Roles.COWBOY,
                Roles.FINANCE,
                ...(isCA9685_localAuthLoginScreen ? [Roles.USER] : []),
            ],
            redirectUrl: '/fmdashboard',
            icon: <HomeIcon />,
        },
    ],
    subFMItemsSecondary: [
        {
            accordionName: 'Cattle Management',
            parentRoles: [
                Roles.ADMIN,
                Roles.FEEDLOT_MANAGER,
                Roles.FINANCE,
                Roles.OFFICE_MANAGER,
                Roles.COWBOY,
            ],
            icon: <CoreCattleManagementIcon />,
            subSideNavItems: [
                {
                    roles: [
                        Roles.ADMIN,
                        Roles.FEEDLOT_MANAGER,
                        Roles.OFFICE_MANAGER,
                    ],
                    redirectUrl: '/lotsummary',
                    label: 'Lots',
                },
                {
                    roles: [
                        Roles.ADMIN,
                        Roles.FEEDLOT_MANAGER,
                        Roles.OFFICE_MANAGER,
                    ],
                    redirectUrl: Constants.Routes.RECEIVE_CATTLE,
                    label: 'Receive Cattle',
                },
                {
                    roles: [
                        Roles.ADMIN,
                        Roles.FEEDLOT_MANAGER,
                        Roles.OFFICE_MANAGER,
                    ],
                    redirectUrl: Constants.Routes.SHIP_CATTLE,
                    label: 'Ship Cattle',
                },
                {
                    roles: [
                        Roles.ADMIN,
                        Roles.FEEDLOT_MANAGER,
                        Roles.OFFICE_MANAGER,
                    ],
                    redirectUrl: Constants.Routes.MOVE_CATTLE,
                    label: 'Move Cattle',
                },
                {
                    roles: [
                        Roles.ADMIN,
                        Roles.FEEDLOT_MANAGER,
                        Roles.OFFICE_MANAGER,
                        Roles.COWBOY,
                    ],
                    redirectUrl: Constants.Routes.YARD_LAYOUT,
                    label: 'Yard Layout',
                },
                {
                    roles: [
                        Roles.ADMIN,
                        Roles.FEEDLOT_MANAGER,
                        Roles.OFFICE_MANAGER,
                    ],
                    redirectUrl: Constants.Routes.BILLING_CATTLE,
                    label: 'Billing',
                },
            ],
        },
        {
            accordionName: 'Feed',
            parentRoles: [
                Roles.ADMIN,
                Roles.FEEDLOT_MANAGER,
                Roles.OFFICE_MANAGER,
            ],
            icon: <FeedIcon />,
            subSideNavItems: [
                {
                    roles: [
                        Roles.ADMIN,
                        Roles.FEEDLOT_MANAGER,
                        Roles.OFFICE_MANAGER,
                    ],
                    redirectUrl: '/penrationscheduletemplates/',
                    label: 'Ration Schedule Templates',
                },
                // { // feature is not ready, hiding it for now.
                //     roles: [
                //         Roles.ADMIN,
                //         Roles.FEEDLOT_MANAGER,
                //         Roles.OFFICE_MANAGER,
                //     ],
                //     redirectUrl: Constants.Routes.RATION_FEED_ORDER,
                //     label: 'Ration Feed Order',
                // },
                {
                    roles: [
                        Roles.ADMIN,
                        Roles.FEEDLOT_MANAGER,
                        Roles.OFFICE_MANAGER,
                    ],
                    redirectUrl: Constants.Routes.RATION,
                    label: 'Rations',
                },
                {
                    roles: [
                        Roles.ADMIN,
                        Roles.FEEDLOT_MANAGER,
                        Roles.OFFICE_MANAGER,
                    ],
                    redirectUrl: Constants.Routes.NEWFEEDCALL,
                    label: 'Feed Call',
                },
                {
                    roles: [
                        Roles.ADMIN,
                        Roles.FEEDLOT_MANAGER,
                        Roles.OFFICE_MANAGER,
                    ],
                    redirectUrl: Constants.Routes.NEWFEEDCALLV1DOT1,
                    label: Constants.headerConstants['feedcallv1.1'],
                    isDisabled: !isCA10586On_refactorFeedCall,
                },
                {
                    roles: [
                        Roles.ADMIN,
                        Roles.FEEDLOT_MANAGER,
                        Roles.OFFICE_MANAGER,
                    ],
                    redirectUrl: Constants.Routes.FEED_STATUS,
                    label: 'Feed Status',
                },

                {
                    roles: [
                        Roles.ADMIN,
                        Roles.FEEDLOT_MANAGER,
                        Roles.OFFICE_MANAGER,
                    ],
                    redirectUrl: Constants.Routes.MANUAL_FEED_ENTRY,
                    label: 'Manual Feed Entry',
                },

                {
                    roles: [
                        Roles.ADMIN,
                        Roles.FEEDLOT_MANAGER,
                        Roles.OFFICE_MANAGER,
                    ],
                    redirectUrl: Constants.Routes.FEED_CALL_ROUTE,
                    label: 'Feed Call Route',
                },
                {
                    roles: [
                        Roles.ADMIN,
                        Roles.FEEDLOT_MANAGER,
                        Roles.OFFICE_MANAGER,
                    ],
                    redirectUrl: Constants.Routes.REPORTS,
                    label: 'Reports',
                },
            ],
        },
        {
            accordionName: 'Commodities',
            parentRoles: [
                Roles.ADMIN,
                Roles.FEEDLOT_MANAGER,
                Roles.OFFICE_MANAGER,
            ],
            icon: <InventoryIcon />,
            subSideNavItems: [
                {
                    roles: [
                        Roles.ADMIN,
                        Roles.FEEDLOT_MANAGER,
                        Roles.OFFICE_MANAGER,
                    ],
                    redirectUrl: Constants.Routes.MANAGE_COMMODITIES,
                    label: 'Manage Commodities',
                },
                {
                    roles: [
                        Roles.ADMIN,
                        Roles.FEEDLOT_MANAGER,
                        Roles.OFFICE_MANAGER,
                    ],
                    redirectUrl: Constants.Routes.SCALE_TICKET,
                    label: 'Scale Tickets',
                },
                {
                    roles: [
                        Roles.ADMIN,
                        Roles.FEEDLOT_MANAGER,
                        Roles.OFFICE_MANAGER,
                    ],
                    redirectUrl: Constants.Routes.COMMODITY_INVENTORY_REPORT,
                    label: 'Reports',
                },
            ],
        },
        {
            accordionName: 'Health',
            parentRoles: [
                Roles.ADMIN,
                Roles.FEEDLOT_MANAGER,
                Roles.HEALTH_MANAGER,
                Roles.OFFICE_MANAGER,
                Roles.COWBOY,
            ],
            icon: <HealthIcon />,
            subSideNavItems: [
                {
                    roles: [
                        Roles.ADMIN,
                        Roles.FEEDLOT_MANAGER,
                        Roles.HEALTH_MANAGER,
                        Roles.OFFICE_MANAGER,
                    ],
                    redirectUrl: Constants.Routes.PROCESSING_LOT_PAGE,
                    label: 'Processing',
                },
                {
                    roles: [
                        Roles.ADMIN,
                        Roles.FEEDLOT_MANAGER,
                        Roles.HEALTH_MANAGER,
                        Roles.OFFICE_MANAGER,
                    ],
                    redirectUrl: Constants.Routes.MEDICAL_INVENTORY,
                    label: 'Medical Inventory',
                },
                {
                    roles: [
                        Roles.ADMIN,
                        Roles.FEEDLOT_MANAGER,
                        Roles.HEALTH_MANAGER,
                        Roles.OFFICE_MANAGER,
                    ],
                    redirectUrl: Constants.Routes.MEDICAL_ITEM_LIST,
                    label: 'Medical Items',
                },
                {
                    roles: [
                        Roles.HEALTH_MANAGER,
                        Roles.ADMIN,
                        Roles.OFFICE_MANAGER,
                        Roles.FEEDLOT_MANAGER,
                    ],
                    redirectUrl: Constants.Routes.HEALTH_PROTOCOLS,
                    label: 'Health Protocols',
                },
                {
                    roles: [
                        Roles.HEALTH_MANAGER,
                        Roles.ADMIN,
                        Roles.OFFICE_MANAGER,
                        Roles.COWBOY,
                        Roles.FEEDLOT_MANAGER,
                    ],
                    redirectUrl: Constants.Routes.TREATMENTS,
                    label: 'Treatments',
                },
                {
                    roles: [
                        Roles.HEALTH_MANAGER,
                        Roles.ADMIN,
                        Roles.FEEDLOT_MANAGER,
                        Roles.OFFICE_MANAGER,
                        Roles.COWBOY,
                    ],
                    redirectUrl: Constants.Routes.HOTLIST,
                    label: 'Hot List',
                },
                {
                    roles: [
                        Roles.HEALTH_MANAGER,
                        Roles.ADMIN,
                        Roles.OFFICE_MANAGER,
                        Roles.FEEDLOT_MANAGER,
                    ],
                    redirectUrl: Constants.Routes.MORTALITY_REPORT,
                    label: 'Mortality Report',
                },
                {
                    roles: [
                        Roles.HEALTH_MANAGER,
                        Roles.OFFICE_MANAGER,
                        Roles.ADMIN,
                        Roles.FEEDLOT_MANAGER,
                        Roles.COWBOY,
                    ],
                    redirectUrl: Constants.Routes.HOSPITAL,
                    label: 'Hospital',
                },
            ],
        },
        {
            accordionName: 'Settings',
            parentRoles: [
                Roles.ADMIN,
                Roles.FEEDLOT_MANAGER,
                Roles.HEALTH_MANAGER,
            ],
            icon: <SettingsIcon />,
            subSideNavItems: [
                {
                    roles: [Roles.ADMIN],
                    redirectUrl: Constants.Routes.BUYERS,
                    label: 'Buyers',
                },
                {
                    roles: [Roles.ADMIN],
                    redirectUrl: Constants.Routes.LOCATIONS,
                    label: 'Buy Locations',
                },
                {
                    roles: [Roles.ADMIN],
                    redirectUrl: Constants.Routes.DESTINATIONS,
                    label: 'Destinations',
                },
                ...(isCA4898On_LoaderList
                    ? [
                          {
                              roles: [Roles.ADMIN, Roles.FEEDLOT_MANAGER],
                              redirectUrl: Constants.Routes.LOADERS,
                              label: 'Loaders',
                          },
                      ]
                    : []),
                {
                    roles: [Roles.ADMIN, Roles.FEEDLOT_MANAGER],
                    redirectUrl: Constants.Routes.MIXING_BINS,
                    label: 'Mixing Bin',
                },
                {
                    roles: [Roles.ADMIN],
                    redirectUrl: Constants.Routes.OWNERS,
                    label: 'Owners',
                },
                {
                    roles: [Roles.ADMIN, Roles.FEEDLOT_MANAGER],
                    redirectUrl: Constants.Routes.PENS,
                    label: 'Pens',
                },
                ...(isCA12073On_QuickBooksIntegration
                    ? [
                          {
                              roles: [Roles.ADMIN],
                              redirectUrl: Constants.Routes.QUICKBOOK,
                              label: 'QuickBooks',
                          },
                      ]
                    : []),
                {
                    roles: [
                        Roles.ADMIN,
                        Roles.FEEDLOT_MANAGER,
                        Roles.HEALTH_MANAGER,
                    ],
                    redirectUrl: Constants.Routes.RIDERS,
                    label: 'Riders',
                },
                {
                    roles: [Roles.ADMIN, Roles.FEEDLOT_MANAGER],
                    redirectUrl: Constants.Routes.TANKS,
                    label: 'Tanks',
                },
                {
                    roles: [
                        Roles.ADMIN,
                        Roles.FEEDLOT_MANAGER,
                        Roles.HEALTH_MANAGER,
                    ],
                    redirectUrl: Constants.Routes.TECHS,
                    label: 'Techs',
                },

                {
                    roles: [Roles.ADMIN, Roles.FEEDLOT_MANAGER],
                    redirectUrl: Constants.Routes.TRUCKS,
                    label: 'Trucks',
                },

                {
                    roles: [Roles.ADMIN],
                    redirectUrl: Constants.Routes.TRUCKING_COMPANY,
                    label: 'Trucking Company',
                },
                ...(isCA9919On_ManageUsersPage
                    ? [
                          {
                              roles: [
                                  Roles.ADMIN,
                                  Roles.FEEDLOT_MANAGER,
                                  Roles.TENANT_ADMIN,
                              ],
                              redirectUrl: Constants.Routes.USER_MANAGEMENT,
                              label: 'User Management',
                              ...(isCA13354On_RestrictLegacyUsersOnUserManagement
                                  ? { authType: Constants.AuthType.LOCAL }
                                  : {}),
                          },
                      ]
                    : []),
                ...(isCA10570On_VendorList
                    ? [
                          {
                              roles: [Roles.ADMIN],
                              redirectUrl: Constants.Routes.VENDORS,
                              label: 'Vendors',
                          },
                      ]
                    : []),
                {
                    roles: isCA12694On_SettingsForAdminAndFeedlotManagerFix
                        ? [
                              Roles.TENANT_ADMIN,
                              Roles.ADMIN,
                              Roles.FEEDLOT_MANAGER,
                          ]
                        : [Roles.TENANT_ADMIN, Roles.FEEDLOT_MANAGER],
                    redirectUrl: Constants.Routes.YARDAGE,
                    label: 'Yardage',
                },
            ],
        },
    ],
};

export const SideNavItems = {
    buyPoSideNavItem,
    financialSideNavItem,
    feedlotManagerSideNavItem,
};

const buyPoRoutes: IAppRoute = {
    appRole: AppRoles.ShippingTracker,
    routes: [
        {
            roles: [
                Roles.BUYER,
                Roles.ADMIN,
                Roles.TENANT_ADMIN,
                Roles.FEEDLOT_MANAGER,
                Roles.OFFICE_MANAGER,
            ],
            path: Constants.Routes.BUY_POS,
            component: <BuyPoList />,
        },
        {
            roles: [
                Roles.BUYER,
                Roles.ADMIN,
                Roles.TENANT_ADMIN,
                Roles.FEEDLOT_MANAGER,
                Roles.OFFICE_MANAGER,
            ],
            path: Constants.Routes.CREATE_BUY_PO,
            component: <BuyPoForm />,
        },
        {
            roles: [
                Roles.BUYER,
                Roles.ADMIN,
                Roles.FEEDLOT_MANAGER,
                Roles.OFFICE_MANAGER,
                Roles.TENANT_ADMIN,
            ],
            path: Constants.Routes.BUYPOID,
            component: <BuyPoForm />,
        },
        {
            roles: [
                Roles.TRUCKING_COMPANY,
                Roles.TENANT_ADMIN,
                Roles.ADMIN,
                Roles.FEEDLOT_MANAGER,
                Roles.OFFICE_MANAGER,
            ],
            path: Constants.Routes.TRUCKINGCOMPANY,
            component: <TruckingCompanyView />,
        },
        {
            roles: [
                Roles.BUYER,
                Roles.ADMIN,
                Roles.FEEDLOT_MANAGER,
                Roles.OFFICE_MANAGER,
                Roles.TENANT_ADMIN,
            ],
            path: Constants.Routes.SHIPPO,
            component: <ShipPoList />,
        },
        {
            roles: [
                Roles.BUYER,
                Roles.ADMIN,
                Roles.FEEDLOT_MANAGER,
                Roles.OFFICE_MANAGER,
                Roles.TENANT_ADMIN,
            ],
            path: Constants.Routes.CREATE_SHIP_PO,
            component: <ShipPOForm />,
        },
        {
            roles: [
                Roles.BUYER,
                Roles.ADMIN,
                Roles.FEEDLOT_MANAGER,
                Roles.OFFICE_MANAGER,
                Roles.TENANT_ADMIN,
            ],
            path: Constants.Routes.SHIPPOID,
            component: <ShipPOForm />,
        },
        {
            roles: [
                Roles.BUYER,
                Roles.ADMIN,
                Roles.FEEDLOT_MANAGER,
                Roles.OFFICE_MANAGER,
                Roles.TENANT_ADMIN,
            ],
            path: Constants.Routes.CATTLEPO_CALENDAR,
            component: <CattlePoCalendar />,
        },
        {
            roles: [Roles.TENANT_ADMIN],
            path: Constants.Routes.BUY_LOCATION_SETTINGS,
            component: <BuyLocationSettings />,
        },
        {
            roles: [Roles.TENANT_ADMIN],
            path: Constants.Routes.DESTINATION_SETTINGS,
            component: <DestinationSettings />,
        },
        {
            roles: [Roles.TENANT_ADMIN],
            path: Constants.Routes.TRUCKING_COMPANY_SETTINGS,
            component: <TruckingCompanySettings />,
        },
        {
            roles: [Roles.TENANT_ADMIN],
            path: Constants.Routes.OWNER_SETTINGS,
            component: <OwnerSettings />,
        },
        {
            roles: [Roles.TENANT_ADMIN],
            path: Constants.Routes.BUYER_SETTINGS,
            component: <BuyerSettings />,
        },
        {
            roles: [Roles.TENANT_ADMIN],
            path: Constants.Routes.PAYEE_SETTINGS,
            component: <PayeeSettings />,
        },
        {
            roles: [Roles.TENANT_ADMIN],
            path: Constants.Routes.VARIABLE_SETTINGS,
            component: <VariableSettings />,
        },
        {
            roles: [Roles.TENANT_ADMIN],
            path: Constants.Routes.TERM_SETTINGS,
            component: <TermSettings />,
        },
    ],
};

const financialRoutes: IAppRoute = {
    appRole: AppRoles.CattleFinanceManager,
    routes: [
        {
            roles: [Roles.FINANCE, Roles.ADMIN, Roles.TENANT_ADMIN],
            path: FinancialRoutes.App.CREATE_OWNER,
            component: <CreateCustomer />,
        },
        {
            roles: [Roles.FINANCE, Roles.ADMIN, Roles.TENANT_ADMIN],
            path: FinancialRoutes.App.CREATE_FEEDLOT,
            component: <CreateFeedLot />,
        },
        {
            roles: [Roles.FINANCE, Roles.ADMIN, Roles.TENANT_ADMIN],
            path: FinancialRoutes.App.CREATE_LOT,
            component: <CreateLot />,
        },
        {
            roles: [Roles.FINANCE, Roles.ADMIN, Roles.TENANT_ADMIN],
            path: FinancialRoutes.App.CREATE_FEEDBILL,
            component: <CreateFeedBillPage />,
        },
        {
            roles: [Roles.FINANCE, Roles.ADMIN, Roles.TENANT_ADMIN],
            path: FinancialRoutes.App.CREATE_CATTLE_PURCHASE,
            component: <CattlePurchasePage />,
        },
        {
            roles: [Roles.FINANCE, Roles.ADMIN, Roles.TENANT_ADMIN],
            path: FinancialRoutes.App.EDIT_CATTLE_PURCHASE_WITH_PARAM,
            component: <CattlePurchasePage />,
        },
        {
            roles: [Roles.FINANCE, Roles.ADMIN, Roles.TENANT_ADMIN],
            path: FinancialRoutes.App.CREATE_CATTLE_SALE,
            component: <CattleSalePage />,
        },
        {
            roles: [Roles.FINANCE, Roles.ADMIN, Roles.TENANT_ADMIN],
            path: FinancialRoutes.App.EDIT_CATTLE_SALE_WITH_PARAM,
            component: <CattleSalePage />,
        },
        {
            roles: [Roles.FINANCE, Roles.ADMIN, Roles.TENANT_ADMIN],
            path: FinancialRoutes.App.CREATE_CATTLE_WEIGHT,
            component: <CattleWeightPage />,
        },
        {
            roles: [Roles.FINANCE, Roles.ADMIN, Roles.TENANT_ADMIN],
            path: FinancialRoutes.App.EDIT_CATTLE_WEIGHT_WITH_PARAM,
            component: <CattleWeightPage />,
        },
        {
            roles: [Roles.FINANCE, Roles.ADMIN, Roles.TENANT_ADMIN],
            path: FinancialRoutes.App.CREATE_CATTLE_DEAD,
            component: <CattleDeadPage />,
        },
        {
            roles: [Roles.FINANCE, Roles.ADMIN, Roles.TENANT_ADMIN],
            path: FinancialRoutes.App.EDIT_CATTLE_DEAD_WITH_PARAM,
            component: <CattleDeadPage />,
        },
        {
            roles: [Roles.FINANCE, Roles.ADMIN, Roles.TENANT_ADMIN],
            path: FinancialRoutes.App.HEDGES,
            component: <Hedges />,
        },
        {
            roles: [Roles.FINANCE, Roles.ADMIN, Roles.TENANT_ADMIN],
            path: FinancialRoutes.App.EDIT_HEDGE_WITH_PARAM,
            component: <EditHedge />,
        },
        {
            roles: [Roles.FINANCE, Roles.ADMIN, Roles.TENANT_ADMIN],
            path: FinancialRoutes.App.CREATE_OPEN_HEDGE,
            component: <HedgePurchaseForm />,
        },
        {
            roles: [Roles.FINANCE, Roles.ADMIN, Roles.TENANT_ADMIN],
            path: FinancialRoutes.App.CREATE_CLOSE_HEDGE,
            component: <HedgeSaleForm />,
        },
        {
            roles: [Roles.FINANCE, Roles.ADMIN, Roles.TENANT_ADMIN],
            path: FinancialRoutes.App.CREATE_RE_LOT,
            component: <ReLotFormPage />,
        },
        {
            roles: [Roles.FINANCE, Roles.ADMIN, Roles.TENANT_ADMIN],
            path: FinancialRoutes.App.EDIT_RE_LOT_WITH_PARAM,
            component: <ReLotFormPage />,
        },
        {
            roles: [Roles.FINANCE, Roles.ADMIN, Roles.TENANT_ADMIN],
            path: FinancialRoutes.App.LOTS,
            component: <LotsPerOwnerPage />,
        },
        {
            roles: [Roles.FINANCE, Roles.ADMIN, Roles.TENANT_ADMIN],
            path: FinancialRoutes.App.CUSTOMER_CLOSEOUT_WITH_PARAM,
            component: <CustomerCloseout />,
        },
        {
            roles: [Roles.FINANCE, Roles.ADMIN, Roles.TENANT_ADMIN],
            path: FinancialRoutes.App.LENDER_REPORT,
            component: <MonthlyFarmCreditReport />,
        },
        {
            roles: [Roles.FINANCE, Roles.ADMIN, Roles.TENANT_ADMIN],
            path: FinancialRoutes.App.SHAWNA_SPREADSHEET,
            component: <ShawnaSpreadSheet />,
        },
        {
            roles: [Roles.FINANCE, Roles.ADMIN, Roles.TENANT_ADMIN],
            path: FinancialRoutes.App.OWNER_REPORT,
            component: <CustomerMonthlyReport />,
        },
        {
            roles: [Roles.FINANCE, Roles.ADMIN, Roles.TENANT_ADMIN],
            path: FinancialRoutes.App.LOT_WITH_PARAM,
            component: <LotDetails />,
        },
        {
            roles: [Roles.FINANCE, Roles.ADMIN, Roles.TENANT_ADMIN],
            path: FinancialRoutes.App.LOT_BALANCE_REPORT,
            component: <LotBalancesReport />,
        },
        {
            roles: [Roles.FINANCE, Roles.ADMIN, Roles.TENANT_ADMIN],
            path: FinancialRoutes.App.QUARTERLY_REPORT,
            component: <QuarterlyReport />,
        },
        {
            roles: [Roles.FINANCE, Roles.ADMIN, Roles.TENANT_ADMIN],
            path: FinancialRoutes.App.PROJECT_BREAKEVEN_REPORT,
            component: <ProjectBreakevenReport />,
        },
        {
            roles: [Roles.FINANCE, Roles.ADMIN, Roles.TENANT_ADMIN],
            path: FinancialRoutes.App.LOT_SUMMARY_REPORT,
            component: <LotSummaryReport />,
        },
        {
            roles: [Roles.FINANCE, Roles.ADMIN, Roles.TENANT_ADMIN],
            path: FinancialRoutes.App.FEEDLOTS,
            component: <FeedLots />,
        },
        {
            roles: [Roles.FINANCE, Roles.ADMIN, Roles.TENANT_ADMIN],
            path: FinancialRoutes.App.FEEDLOT_WITH_PARAM,
            component: <FeedLotDetails />,
        },
        {
            roles: [Roles.FINANCE, Roles.ADMIN, Roles.TENANT_ADMIN],
            path: FinancialRoutes.App.FEEDBILLS,
            component: <FeedBills />,
        },
        {
            roles: [Roles.FINANCE, Roles.ADMIN, Roles.TENANT_ADMIN],
            path: FinancialRoutes.App.EDIT_FEEDBILL_WITH_PARAM,
            component: <EditFeedBillPage />,
        },
        {
            roles: [Roles.FINANCE, Roles.ADMIN, Roles.TENANT_ADMIN],
            path: FinancialRoutes.App.STATEMENTS,
            component: <Statements />,
        },
        {
            roles: [Roles.FINANCE, Roles.ADMIN, Roles.TENANT_ADMIN],
            path: FinancialRoutes.App.CATTLE_FAXES,
            component: <CattleFaxes />,
        },
        {
            roles: [Roles.FINANCE, Roles.ADMIN, Roles.TENANT_ADMIN],
            path: FinancialRoutes.App.INTEREST_RATE_CHANGES,
            component: <InterestRateChanges />,
        },
        {
            roles: [Roles.FINANCE, Roles.ADMIN, Roles.TENANT_ADMIN],
            path: FinancialRoutes.App.SETTINGS,
            component: <Settings />,
        },
    ],
};

const feedlotManagerRoutes: IAppRoute = {
    appRole: AppRoles.FeedlotManager,
    routes: [
        {
            roles: [
                Roles.FEEDLOT_MANAGER,
                Roles.ADMIN,
                Roles.OFFICE_MANAGER,
                Roles.BUYER,
                Roles.HEALTH_MANAGER,
                Roles.COWBOY,
                ...(isCA9685_localAuthLoginScreen ? [Roles.USER] : []),
            ],
            path: Constants.Routes.FM_DASHBOARD,
            component: <FMDashboard />,
        },
        {
            roles: [Roles.FEEDLOT_MANAGER, Roles.ADMIN],
            path: Constants.Routes.PENS,
            component: <PenDetails />,
        },
        {
            roles: [Roles.FEEDLOT_MANAGER, Roles.ADMIN],
            path: Constants.Routes.CREATE_PEN,
            component: <CreatePen />,
        },
        {
            roles: isCA12694On_SettingsForAdminAndFeedlotManagerFix
                ? [Roles.TENANT_ADMIN, Roles.ADMIN, Roles.FEEDLOT_MANAGER]
                : [Roles.TENANT_ADMIN, Roles.FEEDLOT_MANAGER],
            path: Constants.Routes.YARDAGE,
            component: <YardageDetails />,
        },
        {
            roles: [Roles.FEEDLOT_MANAGER, Roles.ADMIN],
            path: Constants.Routes.LOADERS,
            component: <LoaderDetails />,
        },

        ...(isCA12073On_QuickBooksIntegration
            ? [
                  {
                      roles: [Roles.ADMIN],
                      path: Constants.Routes.QUICKBOOK,
                      component: <QuickBookIntegration />,
                  },
              ]
            : []),
        {
            roles: [Roles.ADMIN],
            path: Constants.Routes.LOCATIONS,
            component: <LocationDetails />,
        },
        {
            roles: [Roles.ADMIN],
            path: Constants.Routes.DESTINATIONS,
            component: <DestinationDetails />,
        },
        {
            roles: [Roles.ADMIN],
            path: Constants.Routes.TRUCKING_COMPANY,
            component: <TruckingCompanyDetails />,
        },
        {
            roles: [Roles.ADMIN],
            path: Constants.Routes.BUYERS,
            component: <BuyerDetails />,
        },
        {
            roles: [Roles.FEEDLOT_MANAGER, Roles.ADMIN, Roles.HEALTH_MANAGER],
            path: Constants.Routes.TECHS,
            component: <TechDetails />,
        },
        {
            roles: [Roles.ADMIN],
            path: Constants.Routes.OWNERS,
            component: <OwnerDetails />,
        },
        {
            roles: [Roles.FEEDLOT_MANAGER, Roles.ADMIN],
            path: Constants.Routes.MIXING_BINS,
            component: <MixingBinDetails />,
        },
        {
            roles: [Roles.FEEDLOT_MANAGER, Roles.ADMIN],
            path: Constants.Routes.TRUCKS,
            component: <TruckDetails />,
        },
        {
            roles: [Roles.ADMIN],
            path: Constants.Routes.VENDORS,
            component: <VendorDetails />,
        },
        {
            roles: [Roles.FEEDLOT_MANAGER, Roles.ADMIN, Roles.HEALTH_MANAGER],
            path: Constants.Routes.RIDERS,
            component: <RiderDetails />,
        },
        {
            roles: [Roles.FEEDLOT_MANAGER, Roles.ADMIN],
            path: Constants.Routes.TANKS,
            component: <TankDetails />,
        },
        {
            roles: [Roles.FEEDLOT_MANAGER, Roles.ADMIN, Roles.OFFICE_MANAGER],
            path: Constants.Routes.LOTSUMMARY,
            component: <LotSummary />,
        },
        {
            roles: [Roles.FEEDLOT_MANAGER, Roles.ADMIN, Roles.OFFICE_MANAGER],
            path: Constants.Routes.LOTSUMMARY_DETAIL,
            component: <LotSummaryDetails />,
        },
        {
            roles: [Roles.FEEDLOT_MANAGER, Roles.OFFICE_MANAGER, Roles.ADMIN],
            path: Constants.Routes.RECEIVE_CATTLE + '/*',
            component: <ReceiveCattleList />,
        },
        {
            roles: [Roles.FEEDLOT_MANAGER, Roles.OFFICE_MANAGER, Roles.ADMIN],
            path: Constants.Routes.CREATE_RECEIVE_CATTLE_FORM,
            component: <ReceiveCattleForm />,
        },
        {
            roles: [Roles.FEEDLOT_MANAGER, Roles.OFFICE_MANAGER, Roles.ADMIN],
            path: Constants.Routes.UPDATE_RECEIVE_CATTLE_FORM_WITH_PARAM,
            component: <ReceiveCattleForm />,
        },
        {
            roles: [Roles.FEEDLOT_MANAGER, Roles.OFFICE_MANAGER, Roles.ADMIN],
            path: Constants.Routes.SHIP_CATTLE + `/*`,
            component: <ShipCattle />,
        },
        {
            roles: [Roles.FEEDLOT_MANAGER, Roles.OFFICE_MANAGER, Roles.ADMIN],
            path: Constants.Routes.CREATE_SHIP_CATTLE,
            component: <ShipCattleForm />,
        },
        {
            roles: [Roles.FEEDLOT_MANAGER, Roles.OFFICE_MANAGER, Roles.ADMIN],
            path: Constants.Routes.REALIZER_HISTORY,
            component: <ShipCattleRealizerForm />,
        },
        {
            roles: [Roles.FEEDLOT_MANAGER, Roles.OFFICE_MANAGER, Roles.ADMIN],
            path:
                Constants.Routes.UPDATE_SHIP_CATTLE_REALIZER +
                '/:realizerShipmentId',
            component: <ShipCattleRealizerForm />,
        },
        {
            roles: [Roles.FEEDLOT_MANAGER, Roles.OFFICE_MANAGER, Roles.ADMIN],
            path: Constants.Routes.UPDATE_SHIP_CATTLE + '/:id',
            component: <UpdateShipCattle />,
        },
        {
            roles: [Roles.FEEDLOT_MANAGER, Roles.ADMIN, Roles.OFFICE_MANAGER],
            path: Constants.Routes.MOVE_CATTLE,
            component: <MoveCattle />,
        },
        {
            roles: [Roles.FEEDLOT_MANAGER, Roles.ADMIN, Roles.OFFICE_MANAGER],
            path: Constants.Routes.SHOW_MOVE_CATTLE,
            component: <MoveCattle />,
        },
        {
            roles: [Roles.OFFICE_MANAGER, Roles.FEEDLOT_MANAGER, Roles.ADMIN],
            path: Constants.Routes.BILLING_CATTLE,
            component: <Billing />,
        },
        {
            roles: [Roles.OFFICE_MANAGER, Roles.FEEDLOT_MANAGER, Roles.ADMIN],
            path: Constants.Routes.BILLING_FINAL_INVOICE,
            component: <BillingFinalInvoicePreview />,
        },
        {
            roles: [
                Roles.OFFICE_MANAGER,
                Roles.FEEDLOT_MANAGER,
                Roles.ADMIN,
                Roles.COWBOY,
            ],
            path: Constants.Routes.YARD_LAYOUT,
            component: <YardLayout />,
        },
        {
            roles: [
                Roles.FINANCE,
                Roles.FEEDLOT_MANAGER,
                Roles.ADMIN,
                Roles.OFFICE_MANAGER,
            ],
            path: Constants.Routes.PEN_RATION_SCHEDULE_TEMPLATE,
            component: <PenRationScheduleTemplates />,
        },
        {
            roles: [
                Roles.FINANCE,
                Roles.FEEDLOT_MANAGER,
                Roles.ADMIN,
                Roles.OFFICE_MANAGER,
            ],
            path: Constants.Routes.EDIT_PEN_RATION_TEMPLATES,
            component: <EditPenRationTemplates />,
        },
        {
            roles: [
                Roles.FINANCE,
                Roles.FEEDLOT_MANAGER,
                Roles.ADMIN,
                Roles.OFFICE_MANAGER,
            ],
            path:
                Constants.Routes.CREATE_PEN_RATION_TEMPLATE +
                '/:penRationScheduleTemplateId',
            component: <EditPenRationTemplates />,
        },
        {
            roles: [
                Roles.FINANCE,
                Roles.FEEDLOT_MANAGER,
                Roles.ADMIN,
                Roles.OFFICE_MANAGER,
            ],
            path:
                Constants.Routes.UPDATE_PEN_RATION_TEMPLATES +
                '/:penRationScheduleTemplateId',
            component: <UpdatePenRationScheduleTemplates />,
        },
        {
            roles: [
                Roles.FINANCE,
                Roles.FEEDLOT_MANAGER,
                Roles.ADMIN,
                Roles.OFFICE_MANAGER,
            ],
            path: Constants.Routes.RATION_FEED_ORDER,
            component: <RationFeedOrder />,
        },
        {
            roles: [
                Roles.FINANCE,
                Roles.FEEDLOT_MANAGER,
                Roles.ADMIN,
                Roles.OFFICE_MANAGER,
            ],
            path: Constants.Routes.FEED_ROUTE_CREATION,
            component: <FeedRouteCreation />,
        },
        {
            roles: [
                Roles.FINANCE,
                Roles.FEEDLOT_MANAGER,
                Roles.ADMIN,
                Roles.OFFICE_MANAGER,
            ],
            path: Constants.Routes.RATION,
            component: <Ration />,
        },
        {
            roles: [
                Roles.FINANCE,
                Roles.FEEDLOT_MANAGER,
                Roles.ADMIN,
                Roles.OFFICE_MANAGER,
            ],
            path: Constants.Routes.CREATE_RATION,
            component: <RationFormPage />,
        },
        {
            roles: [
                Roles.FINANCE,
                Roles.FEEDLOT_MANAGER,
                Roles.ADMIN,
                Roles.OFFICE_MANAGER,
            ],
            path: Constants.Routes.UPDATE_RATION + '/:rationVersionId',
            component: <RationFormPage />,
        },
        {
            roles: [
                Roles.FINANCE,
                Roles.FEEDLOT_MANAGER,
                Roles.ADMIN,
                Roles.OFFICE_MANAGER,
            ],
            path: Constants.Routes.NEWFEEDCALL,
            component: <NewFeedCall />,
        },
        {
            roles: [Roles.FEEDLOT_MANAGER, Roles.ADMIN, Roles.OFFICE_MANAGER],
            path: Constants.Routes.NEWFEEDCALLV1DOT1,
            component: <NewFeedCallV2 />,
        },
        {
            roles: [
                Roles.FINANCE,
                Roles.FEEDLOT_MANAGER,
                Roles.ADMIN,
                Roles.OFFICE_MANAGER,
            ],
            path: Constants.Routes.FEED_STATUS,
            component: <FeedStatus />,
        },
        {
            roles: [
                Roles.FINANCE,
                Roles.FEEDLOT_MANAGER,
                Roles.ADMIN,
                Roles.OFFICE_MANAGER,
            ],
            path: Constants.Routes.MANUAL_FEED_ENTRY,
            component: <ManualFeedEntry />,
        },
        {
            roles: [
                Roles.FINANCE,
                Roles.FEEDLOT_MANAGER,
                Roles.ADMIN,
                Roles.OFFICE_MANAGER,
            ],
            path: Constants.Routes.FEED_CALL_ROUTE,
            component: <FeedCallRoute />,
        },
        {
            roles: [
                Roles.FINANCE,
                Roles.FEEDLOT_MANAGER,
                Roles.ADMIN,
                Roles.OFFICE_MANAGER,
            ],
            path: Constants.Routes.REPORTS,
            component: <FeedReports />,
        },
        {
            roles: [
                Roles.FINANCE,
                Roles.FEEDLOT_MANAGER,
                Roles.ADMIN,
                Roles.OFFICE_MANAGER,
            ],
            path: Constants.Routes.PEN_RATION_SCHEDULE + '/:penId',
            component: <PenRationSchedule />,
        },
        {
            roles: [Roles.FEEDLOT_MANAGER, Roles.ADMIN, Roles.OFFICE_MANAGER],
            path: Constants.Routes.MANAGE_COMMODITIES,
            component: <ManageCommodities />,
        },
        {
            roles: [Roles.FEEDLOT_MANAGER, Roles.ADMIN, Roles.OFFICE_MANAGER],
            path: Constants.Routes.ADD_ADJUSTMENT,
            component: <AddCommoditydjustment />,
        },
        {
            roles: [Roles.FEEDLOT_MANAGER, Roles.ADMIN, Roles.OFFICE_MANAGER],
            path: Constants.Routes.UPDATE_CONTRACT + '/:contractId',
            component: <UpdateContract />,
        },
        {
            roles: [
                Roles.FINANCE,
                Roles.FEEDLOT_MANAGER,
                Roles.ADMIN,
                Roles.OFFICE_MANAGER,
            ],
            path: Constants.Routes.SCALE_TICKET,
            component: <ScaleTicketLoad />,
        },
        {
            roles: [
                Roles.FINANCE,
                Roles.FEEDLOT_MANAGER,
                Roles.ADMIN,
                Roles.OFFICE_MANAGER,
            ],
            path: Constants.Routes.CREATE_INBOUND_SCALE_TICKETS,
            component: <CreateScaleTicket />,
        },
        {
            roles: [
                Roles.FINANCE,
                Roles.FEEDLOT_MANAGER,
                Roles.ADMIN,
                Roles.OFFICE_MANAGER,
            ],
            path:
                Constants.Routes.UPDATE_INBOUND_SCALE_TICKETS +
                '/:scaleTicketId',
            component: <EditScaleTicket />,
        },
        {
            roles: [
                Roles.FINANCE,
                Roles.FEEDLOT_MANAGER,
                Roles.ADMIN,
                Roles.OFFICE_MANAGER,
            ],
            path:
                Constants.Routes.UPDATE_OUTGOING_SCALE_TICKETS +
                '/:scaleTicketId',
            component: <EditScaleTicket />,
        },
        {
            roles: [
                Roles.FINANCE,
                Roles.FEEDLOT_MANAGER,
                Roles.ADMIN,
                Roles.OFFICE_MANAGER,
            ],
            path: Constants.Routes.CREATE_OUTGOING_SCALE_TICKETS,
            component: <CreateScaleTicket />,
        },
        {
            roles: [
                Roles.FINANCE,
                Roles.FEEDLOT_MANAGER,
                Roles.ADMIN,
                Roles.OFFICE_MANAGER,
                Roles.HEALTH_MANAGER,
            ],
            path: Constants.Routes.MEDICAL_INVENTORY,
            component: <MedicalInventoryDetails />,
        },
        {
            roles: [
                Roles.FINANCE,
                Roles.FEEDLOT_MANAGER,
                Roles.ADMIN,
                Roles.OFFICE_MANAGER,
                Roles.HEALTH_MANAGER,
            ],
            path: Constants.Routes.ADD_MEDICAL_INVENTORY,
            component: <AddMedicalInventory />,
        },
        {
            roles: [
                Roles.FINANCE,
                Roles.FEEDLOT_MANAGER,
                Roles.ADMIN,
                Roles.OFFICE_MANAGER,
                Roles.HEALTH_MANAGER,
            ],
            path: Constants.Routes.MEDICAL_ITEM_LIST,
            component: <MedicalItemList />,
        },
        {
            roles: [
                Roles.FINANCE,
                Roles.FEEDLOT_MANAGER,
                Roles.ADMIN,
                Roles.OFFICE_MANAGER,
                Roles.HEALTH_MANAGER,
            ],
            path: Constants.Routes.ADD_INVENTORY_ADJUSTMENT,
            component: <AddInventoryAdjustment />,
        },
        {
            roles: [
                Roles.FEEDLOT_MANAGER,
                Roles.ADMIN,
                Roles.OFFICE_MANAGER,
                Roles.HEALTH_MANAGER,
            ],
            path: Constants.Routes.ADD_TREATMENT_PROTOCOL,
            component: <AddTreatmentProtocolPage />,
        },
        {
            roles: [
                Roles.FEEDLOT_MANAGER,
                Roles.ADMIN,
                Roles.OFFICE_MANAGER,
                Roles.HEALTH_MANAGER,
            ],
            path: Constants.Routes.HEALTH_PROTOCOLS,
            component: <HealthTreatmentProtocols />,
        },
        {
            roles: [
                Roles.FEEDLOT_MANAGER,
                Roles.ADMIN,
                Roles.OFFICE_MANAGER,
                Roles.HEALTH_MANAGER,
            ],
            path: Constants.Routes.PROCESSING_PROTOCOL_PAGE,
            component: <AddProcessingProtocolPage />,
        },
        {
            roles: [
                Roles.FEEDLOT_MANAGER,
                Roles.ADMIN,
                Roles.OFFICE_MANAGER,
                Roles.HEALTH_MANAGER,
            ],
            path: Constants.Routes.PROCESSING_WORKSHEET_WITH_PARAM,
            component: <AddProcessingWorksheetPage />,
        },
        {
            roles: [
                Roles.FEEDLOT_MANAGER,
                Roles.ADMIN,
                Roles.OFFICE_MANAGER,
                Roles.HEALTH_MANAGER,
            ],
            path: Constants.Routes.REPROCESSING_WORKSHEET_WITH_PARAM,
            component: <AddProcessingWorksheetPage />,
        },
        {
            roles: [
                Roles.ADMIN,
                Roles.OFFICE_MANAGER,
                Roles.HEALTH_MANAGER,
                Roles.COWBOY,
                Roles.FEEDLOT_MANAGER,
            ],
            path: Constants.Routes.TREATMENTS,
            component: <Treatments />,
        },
        {
            roles: [
                Roles.ADMIN,
                Roles.OFFICE_MANAGER,
                Roles.HEALTH_MANAGER,
                Roles.COWBOY,
                Roles.FEEDLOT_MANAGER,
            ],
            path: Constants.Routes.ADD_TREATMENT,
            component: <EditTreatment />,
        },
        {
            roles: [
                Roles.ADMIN,
                Roles.OFFICE_MANAGER,
                Roles.HEALTH_MANAGER,
                Roles.FEEDLOT_MANAGER,
            ],
            path: Constants.Routes.UPDATE_TREATMENT + '/:cattleTreatmentId',
            component: <EditTreatment />,
        },
        {
            roles: [
                Roles.FEEDLOT_MANAGER,
                Roles.ADMIN,
                Roles.OFFICE_MANAGER,
                Roles.HEALTH_MANAGER,
            ],
            path: Constants.Routes.PROCESSING_LOT_PAGE,
            component: <ProcessingLotPage />,
        },
        {
            roles: [
                Roles.FEEDLOT_MANAGER,
                Roles.ADMIN,
                Roles.OFFICE_MANAGER,
                Roles.HEALTH_MANAGER,
            ],
            path: Constants.Routes.PROCESSING_CHARGEOUT_WITH_PARAM,
            component: <AddProcessingChargeoutPage />,
        },
        {
            roles: [
                Roles.FEEDLOT_MANAGER,
                Roles.ADMIN,
                Roles.OFFICE_MANAGER,
                Roles.HEALTH_MANAGER,
            ],
            path: Constants.Routes.REPROCESSING_CHARGEOUT_WITH_PARAM,
            component: <AddProcessingChargeoutPage />,
        },
        {
            roles: [
                Roles.ADMIN,
                Roles.HEALTH_MANAGER,
                Roles.FEEDLOT_MANAGER,
                Roles.OFFICE_MANAGER,
                Roles.COWBOY,
            ],
            path: Constants.Routes.HOTLIST,
            component: <HotList />,
        },
        {
            roles: [
                Roles.FEEDLOT_MANAGER,
                Roles.ADMIN,
                Roles.OFFICE_MANAGER,
                Roles.HEALTH_MANAGER,
            ],
            path: Constants.Routes.UPDATE_MEDICAL_ITEM,
            component: <UpdateMedicalItemPage />,
        },
        {
            roles: [
                Roles.ADMIN,
                Roles.OFFICE_MANAGER,
                Roles.HEALTH_MANAGER,
                Roles.FEEDLOT_MANAGER,
            ],
            path: Constants.Routes.MORTALITY_REPORT,
            component: <MortalityReport />,
        },
        {
            roles: [
                Roles.ADMIN,
                Roles.OFFICE_MANAGER,
                Roles.HEALTH_MANAGER,
                Roles.FEEDLOT_MANAGER,
            ],
            path: Constants.Routes.CREATE_MORTALITY_REPORT,
            component: <EditMortalityReport />,
        },
        {
            roles: [
                Roles.ADMIN,
                Roles.OFFICE_MANAGER,
                Roles.HEALTH_MANAGER,
                Roles.FEEDLOT_MANAGER,
            ],
            path: Constants.Routes.UPDATE_MORTALITY_REPORT + '/:mortalityId',
            component: <EditMortalityReport />,
        },
        {
            roles: [
                Roles.FINANCE,
                Roles.FEEDLOT_MANAGER,
                Roles.ADMIN,
                Roles.OFFICE_MANAGER,
            ],
            path: Constants.Routes.COMMODITY_INVENTORY_REPORT,
            component: <CommodityInventoryReport />,
        },
        {
            roles: [
                Roles.ADMIN,
                Roles.OFFICE_MANAGER,
                Roles.HEALTH_MANAGER,
                Roles.FEEDLOT_MANAGER,
                Roles.COWBOY,
            ],
            path: Constants.Routes.HOSPITAL,
            component: <Hospital />,
        },
    ],
};

export const privateAppRoutes = {
    buyPoRoutes,
    financialRoutes,
    feedlotManagerRoutes,
};

export const authenticatedRoutes: IRoute[] = [
    {
        path: Constants.Routes.PRODUCTS,
        component: <Products />,
    },
    ...(isCA9919On_ManageUsersPage
        ? [
              {
                  roles: [
                      Roles.ADMIN,
                      Roles.TENANT_ADMIN,
                      Roles.FEEDLOT_MANAGER,
                  ],
                  path: Constants.Routes.USER_MANAGEMENT,
                  component: <UserManagement />,
              },
          ]
        : []),
];

export const unauthenticatedRoutes: IRoute[] = [
    { path: Constants.Routes.SIGN_UP_WITH_PARAM, component: <SignUp /> },
    { path: Constants.Routes.SIGN_UP, component: <SignUp /> },
    { path: Constants.Routes.USER_AUTH, component: <LoginPage /> },
    {
        path: Constants.Routes.FORGOT_PASSWORD,
        component: <ForgotPasswordPage />,
    },
    {
        path: Constants.Routes.RESET_TOKEN,
        component: <TransientResetPasswordPage />,
    },
];

export const publicRoutes: IRoute[] = [
    { path: '/', component: <Dashboard /> },
    { path: '/callback', component: <Callback /> },
    { path: '*', component: <PageNotFound /> },
];

export const RolesForCreateNewButtonAccess = [Roles.FINANCE, Roles.ADMIN];

export const RolesForCreatedBy = [Roles.ADMIN, Roles.OFFICE_MANAGER];

export const RolesForKillGroup = [Roles.ADMIN];

export const RolesForCattleFees = [Roles.ADMIN, Roles.OFFICE_MANAGER];

export const RolesForCattleBrandFees = [Roles.FEEDLOT_MANAGER];

export const RolesForQuickBooks = [
    Roles.ADMIN,
    Roles.OFFICE_MANAGER,
    Roles.TENANT_ADMIN,
];

export const RolesForShipUpdates = [
    Roles.ADMIN,
    Roles.OFFICE_MANAGER,
    Roles.FEEDLOT_MANAGER,
];

export const RolesForInvoice = [
    Roles.ADMIN,
    Roles.INVOICE_MANAGER,
    Roles.OFFICE_MANAGER,
    Roles.FEEDLOT_MANAGER,
    Roles.FINANCE,
];

export const RestrictedUrls = [
    Constants.Routes.CATTLEPO_CALENDAR,
    Constants.Routes.CATTLE_PO_SETTINGS,
    Constants.Routes.TRUCKINGCOMPANY,
];

export const excludedSideNavItems = [
    'Calendar',
    'Settings',
    'Trucking Company',
];
